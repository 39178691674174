import { Accessor, For, Match, Show, Switch, createSignal } from "solid-js";
import { JSX } from "solid-js/jsx-runtime";
import { defaultCoin } from "../../assets/assets";
import { HubbleBrandProduct } from "../../data/products";
import { classNames } from "../../utils/etc";
import HubbleImage from "../../widgets/hubble_image";
import { openUnavailableBrandModal, openVoucherL2 } from "../screens";
import { useClassicContextData } from "~/data/classic_context";
import InfiniteScroll from "~/widgets/infinite_scroll";

export function IconButton(props: {
  children?: JSX.Element;
  onClick?: (event: MouseEvent) => void;
}) {
  return (
    <div
      class="flex max-h-10 cursor-pointer items-center justify-center gap-2.5 rounded-full border border-baseTertiaryDark bg-baseTertiaryLight p-2.5 shadow-iconBtn"
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}

function LargeProductCard({ product }: { product: HubbleBrandProduct }) {
  const isCoinReward = product.voucherProduct.rewardType === "POSTPAID";
  const context = useClassicContextData();

  return (
    <div
      class="flex cursor-pointer items-center pb-5 pl-5"
      onClick={() => {
        if (product.voucherProduct.status == "INACTIVE") {
          openUnavailableBrandModal({
            product,
          });
          return;
        }
        openVoucherL2({ productId: product.brandKey });
      }}
    >
      <HubbleImage
        src={product.voucherProduct.iconImageUrl}
        alt={product.voucherProduct.title}
        class={`h-[116px] w-[104px]  ${product.voucherProduct.status == "INACTIVE" ? "opacity-50" : ""}`}
      />
      <div class="flex flex-grow flex-col items-start pl-3">
        <span
          class={classNames(
            "text-h5 text-basePrimaryDark",
            `${product.voucherProduct.status == "INACTIVE" ? "opacity-50" : ""}`
          )}
        >
          {product.voucherProduct.title}
        </span>
        <Show when={product.voucherProduct.status == "INACTIVE"}>
          <span class="text-f16Bold text-errorDark">
            Temporarily unavailable{" "}
          </span>
        </Show>
        <Show when={product.tags.includes("OFFLINE")}>
          <span class="font-semibold leading-[140%] tracking-[-0.19px]">
            In-store
          </span>
        </Show>
        <span class="text-medium text-textNormal">
          {product.displayTags.join(" • ")}
        </span>
        <Switch>
          <Match when={isCoinReward}>
            <span class="flex items-center gap-1">
              <img
                class="h-6 w-6"
                src={
                  context.isLoggedIn && context.clientConfig.coinIconUrl
                    ? context.clientConfig.coinIconUrl
                    : defaultCoin
                }
                alt="coin"
              />
              <span class="text-h3 text-coinPercentageOrange">
                {product.voucherProduct.discountPercentage}
                <span class="self-end text-bolder uppercase">%</span>
              </span>
            </span>
          </Match>
          <Match when={!isCoinReward}>
            <span class="text-h3 text-successDark">
              {product.voucherProduct.discountPercentage}
              <span class="text-bolder uppercase">% off</span>
            </span>
          </Match>
        </Switch>
      </div>
    </div>
  );
}

export function BrandListing(props: {
  header: string;
  products: HubbleBrandProduct[];
  class?: string;
  scrollNext?: () => void;
  hasMore?: Accessor<boolean>;
}) {
  return (
    <div class={props.class}>
      <Show when={props.header != ""}>
        <div class={`no-scrollbar pl-5 text-h5Bold text-textDark`}>
          {props.header}
        </div>
      </Show>
      <Show when={props.scrollNext && props.hasMore}>
        <InfiniteScroll
          hasMore={props.hasMore?.() ?? false}
          next={props.scrollNext!}
        >
          <For each={props.products}>
            {(product) => <LargeProductCard product={product} />}
          </For>
        </InfiniteScroll>
      </Show>
      <Show when={!props.scrollNext}>
        <For each={props.products}>
          {(product) => <LargeProductCard product={product} />}
        </For>
      </Show>
    </div>
  );
}
