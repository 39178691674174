import { useNavigate, useSearchParams } from "@solidjs/router";
import {
  Accessor,
  Match,
  Setter,
  Switch,
  createEffect,
  createSignal,
  onMount,
  untrack,
} from "solid-js";
import { Show, getRequestEvent } from "solid-js/web";
import { AboutGiftCard } from "~/components/about_gift_card";
import { useModal } from "~/components/modal";
import { setAuth } from "~/data/auth";
import { config } from "~/data/config";
import { logIntoHubble } from "~/screens/hubble_otp_auth/hubble_otp_auth";
import { Product } from "~/server/types/search";
import { Cookie, Mode, Optional } from "~/types";
import { getCookie } from "~/utils/client_cookie";
import { PhosphorIcon } from "~/widgets/icons";
import { Spacer } from "~/widgets/spacer";
import { zIndexes } from "~/z_indexes";
import { HeaderMenu } from "../header_menu";
import { Search, getSearchResults } from "../search/search";
import { webEventManager } from "~/data/events";
import { profileIcon } from "~/assets/assets";
import { BusinessMenuCta } from "../business_menu";
import { getCoinsSummary } from "~/server/apis/client_apis";
import { CoinsPillOrBranding } from "./coinspill_or_branding";
import { AxisHeader } from "../login-rnr/axis_header";

type HeaderProps = {
  headerImageUrl?: string;
  showActions: boolean;
  isLoggedIn: Accessor<boolean>;
  mode: Mode;
  coins?: Accessor<number>;
  showCoins?: Accessor<boolean>;
  setRefreshCoins?: Setter<boolean>;
  userName?: string;
  clientId?: string;
  hideSearchBar?: boolean;
};

export const [isFreshLogin, setIsFreshLogin] =
  createSignal<Optional<boolean>>(null);

export function Header(props: HeaderProps) {
  const [searchParams] = useSearchParams();

  let headerImageHref = config.paths.homePageUrl;
  let headerImageUrl = props.headerImageUrl;

  if (searchParams.clientId) {
    if (searchParams.headerImageUrl) {
      headerImageUrl = searchParams.headerImageUrl;
    }

    if (searchParams.headerImageHref) {
      headerImageHref = searchParams.headerImageHref;
    }
  }

  const navigate = useNavigate();
  const { setIsModalOn, updateModalContentGetter } = useModal()!;
  const [isSearchActive, setIsSearchActive] = createSignal(false);
  const [clearSearch, setClearSearch] = createSignal(false);
  const [showMenu, setShowMenu] = createSignal(false);
  const [allBrands, setAllBrands] = createSignal<Product[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = createSignal<boolean>(false);

  const [isBusinessDropdownOpen, setIsBusinessDropdownOpen] =
    createSignal<boolean>(false);
  const [latestCoinsBalance, setLatestCoinsBalance] = createSignal<
    number | undefined
  >(undefined);
  const [refreshLatestBalance, setRefreshLatestBalance] = createSignal(false);

  async function fetchLatestBalance() {
    const response = await getCoinsSummary();
    setLatestCoinsBalance(response.totalAvailable);
  }

  createEffect(async () => {
    if (refreshLatestBalance()) {
      await fetchLatestBalance();
      untrack(() => {
        setRefreshLatestBalance(false);
      });
    }
  });

  onMount(async () => {
    setAllBrands(
      await getSearchResults(
        "",
        { filterOutInactive: false },
        { pageNo: 0, limit: 30 }
      )
    );

    const sid = getCookie(Cookie.SessionId);
    if (sid) {
      setAuth(() => {
        return {
          sessionId: sid,
        } as any;
      });
    }

    const requestEvent = getRequestEvent();
    if (requestEvent?.locals.logoUrl) {
      headerImageUrl = requestEvent.locals.logoUrl;
    }
  });

  function buildSearchBar(title?: string) {
    return (
      <Search
        setIsSearchActive={setIsSearchActive}
        setClearSearch={setClearSearch}
        clearSearch={clearSearch}
        isSearchActive={isSearchActive}
        allBrands={allBrands}
        title={title}
      />
    );
  }

  if (props.clientId === config.axisClientId) {
    return (
      <AxisHeader
        isLoggedIn={props.isLoggedIn}
        mode={props.mode}
        coins={props.coins}
        showCoins={props.showCoins}
        setRefreshCoins={setRefreshLatestBalance}
        isSearchActive={isSearchActive}
        setIsSearchActive={setIsSearchActive}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
        latestCoinsBalance={latestCoinsBalance}
        setRefreshLatestBalance={setRefreshLatestBalance}
        buildSearchBar={
          props.hideSearchBar ?? false
            ? () => <></>
            : () => buildSearchBar("Search for brands")
        }
      />
    );
  }

  return (
    <div
      class="sticky top-0 w-full bg-baseTertiaryLight sm:h-[77px]"
      style={{
        "z-index": zIndexes.header,
      }}
    >
      <div class="flex flex-col items-center ">
        <div class="ml-4 mr-4 flex w-full flex-row items-center justify-between px-4 py-3 lg:mx-4 lg:max-w-[1136px] lg:px-0 lg:py-4">
          <div
            class={`absolute left-0 right-0  h-screen w-full  bg-baseDark transition-all duration-300 md:top-20 ${isSearchActive() ? "opacity-40" : "pointer-events-none opacity-0"} ${isSearchActive() ? "top-32" : "top-20"} ${isDropdownOpen() || isBusinessDropdownOpen() ? "opacity-40" : "pointer-events-none opacity-0"}`}
          />
          <div class="w-fit lg:w-[286px]">
            <HeaderLogo
              imageUrl={headerImageUrl}
              imageHref={headerImageHref}
              mode={props.mode}
            />
          </div>
          <Show when={props.showActions}>
            <>
              <div class="hidden md:flex">{buildSearchBar()}</div>
              <div class="flex flex-row items-center justify-end text-textDark lg:w-[286px]">
                <Show when={props.mode === "hubble" && !props.isLoggedIn()}>
                  <div class="hidden md:flex">
                    <BusinessMenuCta
                      setIsDropdownOpen={setIsBusinessDropdownOpen}
                      isDropdownOpen={isBusinessDropdownOpen}
                    />
                  </div>
                </Show>
                <Spacer width={16} />
                <Show when={props.isLoggedIn()}>
                  <CoinsPillOrBranding
                    mode={props.mode}
                    showCoins={props.showCoins}
                    setRefreshCoins={setRefreshLatestBalance}
                    setRefreshLatestBalance={setRefreshLatestBalance}
                    userName={props.userName}
                    latestCoinsBalance={latestCoinsBalance}
                    coins={props.coins}
                  />
                </Show>
                <Spacer width={16} />
                <Switch>
                  <Match when={!(isFreshLogin() ?? props.isLoggedIn)}>
                    <div class="hidden lg:inline-flex">
                      <LoginButton />
                    </div>
                  </Match>
                  <Match when={true}>{buildHeaderMenu()}</Match>
                </Switch>
                <div class="lg:hidden">
                  <Show when={!(isFreshLogin() ?? props.isLoggedIn)}>
                    {buildHeaderMenu()}
                  </Show>
                </div>
                <div
                  class="mr-3 md:hidden"
                  classList={{
                    "mr-2": props.mode === "rnr",
                  }}
                >
                  <div>{buildSearchIcon()}</div>
                </div>
                <div class="lg:hidden">{buildMenuButtonListIcon()}</div>
              </div>
            </>
          </Show>
        </div>
        <div class="flex w-full flex-row items-center justify-center md:hidden">
          <Show when={isSearchActive()}>{buildSearchBar()}</Show>
        </div>
        {/* // looks deprecated */}
        <Show when={showMenu()}>{buildMenu()}</Show>
      </div>
    </div>
  );

  function LoginButton() {
    return (
      <div
        class=" shadow flex h-10 w-fit cursor-pointer flex-row items-center justify-center rounded-[30px] border border-neutral-800 bg-baseTertiaryLight  px-[10px] "
        onClick={() => {
          logIntoHubble(() => {
            console.log(`setting sid cookie`);
          }, webEventManager);
        }}
      >
        <div class="px-[3.61px] text-center text-mediumBold text-textDark">
          Log in / Sign up
        </div>
      </div>
    );
  }

  function buildHeaderMenu() {
    return (
      <HeaderMenu
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
        isLoggedIn={props.isLoggedIn()}
        showBusinessMenu={props.mode === "hubble"}
        mode={props.mode}
      />
    );
  }

  function buildMenuButtonListIcon() {
    return (
      <div
        class="cursor-pointer"
        onClick={() => {
          setIsDropdownOpen(!isDropdownOpen());
        }}
        tabIndex="0"
        onFocusOut={async () => {
          await new Promise((resolve) => setTimeout(resolve, 250));
          if (isDropdownOpen()) {
            setIsDropdownOpen(false);
          }
        }}
      >
        <Show
          when={isDropdownOpen()}
          fallback={<PhosphorIcon name="list" fontSize={26} size={"light"} />}
        >
          <PhosphorIcon name="x" fontSize={26} size={"light"} />
        </Show>
      </div>
    );
  }

  function buildSearchIcon() {
    return (
      <div
        class="cursor-pointer "
        onClick={() => {
          setShowMenu(false);
          setIsSearchActive(!isSearchActive());
        }}
      >
        <PhosphorIcon name="magnifying-glass" fontSize={26} size={"light"} />
      </div>
    );
  }

  // looks deprecated
  function buildMenu() {
    return (
      <div class="inline-flex h-[102px]  w-full flex-col items-start justify-start gap-[13px] px-2">
        <div class="flex h-[88px] flex-col items-start justify-start">
          <Show
            when={!(isFreshLogin() ?? props.isLoggedIn)}
            fallback={
              <div
                class="inline-flex h-11 cursor-pointer items-center justify-start gap-1 self-stretch rounded-lg pl-1 pr-3"
                onClick={() => {
                  navigate("/profile", {
                    resolve: true,
                  });
                }}
              >
                <img src={profileIcon} class="h-4" alt="profile" />
                <div class="text-neutral-700z shrink grow basis-0 text-[15px] text-bolder leading-[4px]">
                  Profile
                </div>
              </div>
            }
          >
            <div
              class="inline-flex h-11 cursor-pointer items-center justify-start gap-1 self-stretch rounded-lg pl-1 pr-3"
              onClick={() => {
                logIntoHubble(() => {
                  console.log(`setting sid cookie`);
                }, webEventManager);
              }}
            >
              <img src={profileIcon} class="h-4" alt="login" />
              <div class="shrink grow basis-0 text-[15px] text-bolder leading-[4px] text-neutral-700">
                Log in / Sign up
              </div>
            </div>
          </Show>
          <div
            class="inline-flex h-11 cursor-pointer items-center justify-start gap-1 self-stretch rounded-lg bg-baseTertiaryLight pl-1 pr-3"
            onClick={() => {
              updateModalContentGetter(() => {
                return <AboutGiftCard />;
              });

              setIsModalOn(true);
            }}
          >
            <PhosphorIcon name="question" size="fill" />
            <div class="shrink grow basis-0 text-mediumBolder text-textDark">
              What’s a gift card?
            </div>
          </div>
        </div>
      </div>
    );
  }
}

type HeaderLogoProps = {
  mode: Mode; // need this to determine image's height
  imageUrl?: string;
  imageHref: string;
};

function HeaderLogo(props: HeaderLogoProps) {
  const navigate = useNavigate();

  return (
    <img
      class="cursor-pointer "
      classList={{
        "h-[36px] lg:h-[48px]": props.mode === "rnr",
        "h-[24px] ": props.mode !== "rnr",
      }}
      src={
        props.imageUrl ??
        "https://s3.ap-south-1.amazonaws.com/assets.hubble/web-assets/hubble_full.svg"
      }
      alt="Hubble Logo"
      onClick={() => {
        navigate(props.imageHref);
      }}
    />
  );
}
