import {
  createEffect,
  createResource,
  createSignal,
  For,
  Match,
  onCleanup,
  Show,
  Switch,
  useContext,
} from "solid-js";
import { assetCardIcon, assetUpiApps, assetUpiIcon } from "../../assets/assets";
import { OrderDetails, RedemptionResponse } from "../../server/types/order";
import {
  getStoreOrderDetails,
  initPgRedemption,
  payOrderStandardCheckout,
} from "../../data/products";
import {
  goBack,
  HubbleRoute,
  popUntilHome,
  pushPage,
} from "../../shared_states/modal";
import {
  showSnackBar,
  snackApiCallWrapper,
} from "../../shared_states/snackbar";
import { classNames } from "../../utils/etc";
import { createJob, JobWrapper } from "../../utils/job";
import { toRupees } from "../../utils/number";
import { openRazorpayCheckout } from "../../utils/third_party/razorpay";
import { ButtonRegular, ThreeDotLoader } from "../../widgets/button";
import { PhosphorIcon } from "../../widgets/icons";
import { RedemptionTransactions } from "../transactions/transactions";
import { VoucherCart } from "../voucher_edit/voucher";
import styles from "./payment_options.module.scss";
import { VoucherGenSuccess } from "./voucher_gen_success";
import { config } from "../../data/config";
import { sdkEventManager, HubbleEvent } from "../../data/events";
import { SimulatedTransactionDetails } from "~/server/types/brand";
import { PaymentLoader } from "./payment_loader";
import { Spacer } from "~/widgets/spacer";
import { useSearchParams } from "@solidjs/router";
import { useClassicContextData } from "~/data/classic_context";
import { isAndroid, isIOS } from "~/utils/platform";
import { UpiIdInputScreen } from "../upi_id_input/upi_id_input";
import PaymentStatusPollingScreen from "../payment_status_polling/payment_status_polling";

function getNameForPaymentMode(transaction: SimulatedTransactionDetails) {
  switch (transaction.paymentMode) {
    case "CARD":
      return "Credit / Debit card";
    case "UPI":
      return "UPI";
  }
}
async function onPaymentComplete({
  orderId,
  cart,
}: {
  orderId: string;
  cart: VoucherCart;
}) {
  goBack();
  // wait for 300ms
  await new Promise((resolve) => setTimeout(resolve, 300));
  pushPage(
    () => (
      <VoucherGenLoader
        onVoucherGenerated={async (order) => {
          if (order.status == "COMPLETED") {
            await popUntilHome();
            sdkEventManager.sendEvent(HubbleEvent.PAYMENT_SUCCESS, {
              brandName: cart.voucherProduct?.name,
              amount: order.amount,
              vouchersCount: order.orderProductDetails?.vouchers?.length,
            });

            pushPage(
              () => (
                <VoucherGenSuccess
                  orderId={order.id}
                  orderDetails={order}
                  onClose={() => goBack()}
                  refreshProfile={true}
                />
              ),
              {},
              HubbleRoute.VOUCHER_GENERATION_SUCCESS
            );
          }

          if (order.status == "FAILED") {
            sdkEventManager.sendEvent(HubbleEvent.PAYMENT_FAIL, {
              brandName: cart.voucherProduct?.name,
              amount: order.amount,
              vouchersCount: order.orderProductDetails?.vouchers?.length,
            });

            goBack(() => {
              pushPage(
                () => <VoucherGenerationFailed />,
                {},
                HubbleRoute.VOUCHER_GENERATION_FAIL
              );
            });
          }
        }}
        orderId={orderId}
      />
    ),
    {},
    HubbleRoute.VOUCHER_GENERATION_LOADER
  );
}

function launchUpiCollectFlow({
  redemptionResponse,
  cart,
}: {
  redemptionResponse: RedemptionResponse;
  cart: VoucherCart;
}) {
  pushPage(
    () =>
      UpiIdInputScreen({
        amount: redemptionResponse.amount,
        orderId: redemptionResponse.depositPaymentDetails.id,

        onCollectRequestSent: () => {
          pushPage(
            () => (
              <PaymentStatusPollingScreen
                orderId={redemptionResponse.depositPaymentDetails.id}
                onPaymentComplete={() => {
                  goBack();
                  onPaymentComplete({
                    orderId: redemptionResponse.storeOrderDetails.id,
                    cart: cart,
                  });
                }}
                onPaymentFailed={(failureReason: string) => goBack()}
              />
            ),
            { isModal: false },
            HubbleRoute.PAYMENT_STATUS_POLLING
          );
        },
      }),
    { isModal: false },
    HubbleRoute.UPI_ID_INPUT
  );
}

function launcUpiIntentFlow({
  redemptionResponse,
  initRedemptionJob,
  cart,
}: {
  redemptionResponse: RedemptionResponse;
  initRedemptionJob: JobWrapper<{
    res: RedemptionResponse;
    transaction: SimulatedTransactionDetails;
  }>;
  cart: VoucherCart;
}) {
  pushPage(
    () => (
      <PaymentLoader
        amount={redemptionResponse.depositPaymentDetails.amount}
        onPaymentComplete={() => {
          onPaymentComplete({
            orderId: redemptionResponse.storeOrderDetails.id,
            cart: cart,
          });
        }}
        onPaymentFailed={(failureReason: string) =>
          console.log(`Payment failed`, failureReason)
        }
        orderId={redemptionResponse.depositPaymentDetails.id}
        brandImageUrl={
          cart.voucherProduct?.voucherProductMetadata.iconImageUrl ?? ""
        }
        reInit={() => {
          initRedemptionJob.run();
        }}
        onClose={goBack}
      />
    ),
    {
      isModal: true,
      disableCloseButton: true,
      preventClose: true,
      enterAnimation: false,
    },
    HubbleRoute.PAYMENT_LOADER
  );
}

async function launchRazorpay({
  redemptionResponse,
  paymentMode,
  email,
  phoneNumber,
  cart,
}: {
  redemptionResponse: RedemptionResponse;
  paymentMode: "CARD" | "UPI";
  email: string;
  phoneNumber: string;
  cart: VoucherCart;
}) {
  const order = await snackApiCallWrapper(
    payOrderStandardCheckout(redemptionResponse.depositPaymentDetails.id)
  );

  openRazorpayCheckout({
    amount: redemptionResponse.amount,
    email: email,
    mode: paymentMode,
    orderId: order.providerOrderId,
    phone: phoneNumber,
    onSuccess: () => {
      onPaymentComplete({
        orderId: redemptionResponse.storeOrderDetails.id,
        cart: cart,
      });
    },
  });
}

export function PaymentOptions(props: { cart: VoucherCart }) {
  const context = useClassicContextData();

  const v4Home = context.isLoggedIn ? context.v4HomePage : null;

  const [currentTransaction, setCurrentTransaction] =
    createSignal<SimulatedTransactionDetails>(
      props.cart.dryRunTransaction!.find((r) => r.paymentMode === "UPI")!
    );
  const [queryParams, setQueryParams] = useSearchParams();

  const initRedemptionJob = createJob({
    errorCallback: (e) => {
      showSnackBar({ level: "error", message: e.message });
    },
    successCallback: async ({ res, transaction }) => {
      if (transaction.paymentMode == "UPI") {
        if (
          config.isRazorpayEnabled ||
          (queryParams.clientId?.startsWith(config.clientIdPrefix.onepercent) &&
            isAndroid())
        ) {
          launchRazorpay({
            email: v4Home?.user.email ?? "",
            phoneNumber: v4Home?.user.phoneNumber ?? "",
            redemptionResponse: res,
            paymentMode: "UPI",
            cart: props.cart,
          });
        } else if (
          queryParams.clientId?.startsWith(config.clientIdPrefix.onepercent) &&
          isIOS()
        ) {
          launchUpiCollectFlow({
            redemptionResponse: res,
            cart: props.cart,
          });
        } else {
          launcUpiIntentFlow({
            redemptionResponse: res,
            initRedemptionJob,
            cart: props.cart,
          });
        }
      } else {
        launchRazorpay({
          email: v4Home?.user.email ?? "",
          phoneNumber: v4Home?.user.phoneNumber ?? "",
          redemptionResponse: res,
          paymentMode: "CARD",
          cart: props.cart,
        });
      }
    },
    initialJob: async () => {
      const transaction = currentTransaction();

      const res = await snackApiCallWrapper(
        initPgRedemption({
          amount: transaction.redemptionAmount,
          paymentMode: transaction.paymentMode,
          productId: props.cart.voucherProductId,
          denominationDetails: props.cart.denominations?.map((d) => {
            return {
              denomination: d.denomination,
              quantity: d.quantity,
            };
          }),
          variantId: props.cart.variantId,
          consumeCoins: props.cart.useCoins,
          couponCode: props.cart.couponCode,
        })
      );

      sdkEventManager.sendEvent(HubbleEvent.PAYMENT_INITIATED, {
        brandName: props.cart.voucherProduct?.name,
        amount: res.amount,
        paymentMode: transaction.paymentMode,
        consumeCoins: props.cart.useCoins,
        couponCode: props.cart.couponCode,
        productId: props.cart.voucherProductId,
      });

      return { res, transaction };
    },
  });

  return (
    <div class={styles.paymentOptions}>
      <Show when={initRedemptionJob.jobState() == "running"}>
        <div class={styles.initiationOverlay}>
          <ThreeDotLoader color="#000" />
        </div>
      </Show>
      <BackButton />

      <span class={styles.pageTitle}>Complete payment</span>
      <span class={styles.pageSubtitle}>
        Item: {props.cart.voucherProduct?.name} voucher worth{" "}
        {toRupees(props.cart.dryRunTransaction![0].redemptionAmount)}{" "}
      </span>

      <div class={styles.optionCards}>
        <For
          each={
            props.cart.dryRunTransaction?.filter(
              (t) => t.paymentRoute != "WALLET"
            ) || []
          }
        >
          {(transaction) => {
            return (
              <OptionCardContent
                name={getNameForPaymentMode(transaction)}
                totalAmount={transaction.redemptionAmount}
                payableAmount={transaction.dueAmount}
                savings={transaction.discountDetails.totalDiscount.amount}
                mode={transaction.paymentMode}
                onClick={async () => {
                  setCurrentTransaction(transaction);
                  initRedemptionJob.run();
                }}
              />
            );
          }}
        </For>
      </div>
    </div>
  );
}

export function VoucherGenLoader(props: {
  orderId: string;
  onVoucherGenerated: (status: OrderDetails) => void;
}) {
  const [order, manageOrder] = createResource(() => {
    return getStoreOrderDetails(props.orderId);
  }, {});

  const interval = setInterval(() => {
    if (!order.loading) {
      manageOrder.refetch();
    }
  }, 5000);

  onCleanup(() => {
    clearInterval(interval);
  });

  createEffect(() => {
    if (!order()) return;
    if (order.loading) return;
    if (order.error) return;

    if (
      order()?.status != "INITIALISED" &&
      order()?.status != null &&
      order()?.status != "PROCESSING"
    ) {
      clearInterval(interval);
      props.onVoucherGenerated(order()!);
    }
  });

  return (
    <div class={styles.voucherGenLoader}>
      <span class={styles.text}>Generating voucher</span>
      <ThreeDotLoader color="#000" />
    </div>
  );
}

export function VoucherGenerationFailed() {
  return (
    <div class={styles.voucherGenerationFailed}>
      <span class={styles.text}>Voucher generation failed</span>
      <span class={styles.error}>
        Funds deducted will be refunded, please try again
      </span>

      <ButtonRegular
        onClick={async () => {
          await popUntilHome();
          pushPage(
            () => <RedemptionTransactions />,
            {},
            HubbleRoute.TRANSACTIONS
          );
        }}
        isEnabled={true}
      >
        Close
      </ButtonRegular>
    </div>
  );
}

function OptionCardContent(props: {
  totalAmount: number;
  payableAmount: number;
  savings: number;
  name: string;
  mode: "UPI" | "CARD";
  onClick: () => void;
}) {
  return (
    <div
      class={classNames(styles.OptionCardContent, styles.optionCard)}
      onClick={props.onClick}
    >
      <div class={styles.heading}>
        <span class={styles.name}>{props.name}</span>
        <span class={styles.savings}>Saving {toRupees(props.savings)}</span>
      </div>
      <span class={styles.paymentText}>
        You'll pay: {toRupees(props.payableAmount)}{" "}
        <span class={styles.actualPrice}>{toRupees(props.totalAmount)}</span>
      </span>
      <div class={styles.optionCardChildren}>
        <Switch>
          <Match when={props.mode == "CARD"}>
            <div class={styles.cardPaymentOptionButton}>
              <PhosphorIcon name="credit-card" class={styles.icon} />
              <div class={styles.content}>
                <span class={styles.title}>Pay via credit/debit card</span>
                <img
                  src={assetCardIcon}
                  class={styles.paymentIcon}
                  alt="Credit card providers"
                />
              </div>
              <PhosphorIcon
                name="caret-right"
                size="bold"
                class={styles.caret}
              />
            </div>
          </Match>

          <Match when={props.mode == "UPI"}>
            <div class={styles.cardPaymentOptionButton}>
              <img src={assetUpiIcon} class={styles.imageIcon} alt="Upi" />
              <div class={styles.content}>
                <span class={styles.title}>Pay using UPI apps</span>
                <img
                  src={assetUpiApps}
                  class={styles.paymentIcon}
                  alt="Upi apps"
                />
              </div>
              <PhosphorIcon
                name="caret-right"
                size="bold"
                class={styles.caret}
              />
            </div>
          </Match>
        </Switch>
      </div>
    </div>
  );
}

function BackButton() {
  return (
    <PhosphorIcon
      name="arrow-left"
      class={styles.backButton}
      onClick={() => goBack()}
    />
  );
}
