export class APIError extends Error {
  constructor(
    public message: string,
    public code: number
  ) {
    super(message);
  }
}

export function hubbleFetch(
  url: string | URL,
  options?: RequestInit
): Promise<any> {
  return fetch(url, options)
    .then(async (res) => {
      if (!res.ok) {
        if (res.status === 401) {
          throw new APIError("Unauthorized", 401);
        }

        if (res.status >= 400) {
          return res
            .json()
            .catch((error) => {
              throw new APIError(
                "Unknown error occurred. Please try again.",
                400
              );
            })
            .then((body: any) => {
              throw new APIError(
                body.subErrors
                  ? body.subErrors.length > 0
                    ? body.subErrors[0].message
                    : body.message
                  : body.message,
                400
              );
            });
        }

        throw new APIError(res.statusText, 400);
      }

      if (res.status === 204) {
        return;
      }
      return res.json();
    })
    .catch((error) => {
      throw error;
    });
}
