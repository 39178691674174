import { initParams } from "../../data/auth";
import { config } from "../../data/config";

declare const Razorpay: any;

interface RzpResponse {
  razorpay_order_id: string;
  razorpay_payment_id: string;
  razorpay_signature: string;
}

type RazorpayCheckoutProps = {
  orderId: string;
  amount: number;
  email: string;
  phone: string;
  mode: "CARD" | "UPI";
  onDismiss?: () => void;
  onFailure?: (response: any) => void;
  onSuccess?: (response: any) => void;
};

export async function openRazorpayCheckout(props: RazorpayCheckoutProps) {
  const options: { [key: string]: any } = {
    key: config.razorpayKey,
    order_id: props.orderId,
    amount: props.amount,
    currency: "INR",
  };

  if (props.mode == "UPI" && initParams().webview) {
    options["webview_intent"] = true;
  }

  const cardConfig = {
    prefill: { email: props.email, contact: props.phone, method: "card" },
    config: {
      display: {
        blocks: {
          banks: {
            name: "Pay via Card",
            instruments: [{ method: "card" }],
          },
        },
        sequence: ["block.banks"],
        preferences: {
          show_default_blocks: false,
        },
      },
    },
  };

  const upiConfig = {
    prefill: {
      email: props.email,
      contact: props.phone,
      method: "upi",
      flows: ["intent", "qr"],
    },
    config: {
      display: {
        blocks: {
          upi: {
            name: "Pay via UPI",
            instruments: [{ method: "upi", flows: ["intent", "qr"] }],
          },
        },
        hide: [
          {
            method: "upi",
            flows: ["collect"],
          },
        ],
        sequence: ["block.upi"],
        preferences: {
          show_default_blocks: false,
        },
      },
    },
  };

  const finalConfig = props.mode == "CARD" ? cardConfig : upiConfig;

  const Razorpay = await getRazorpayInstance();
  const rzp = Razorpay({
    ...options,
    ...finalConfig,
    modal: {
      ondismiss:
        props.onDismiss ??
        function () {
          console.log("Rzp closed");
        },
    },
    handler:
      props.onSuccess ??
      function (response: any) {
        console.log(response);
      },
  });
  rzp.on(
    "payment.failed",
    props.onFailure ??
      function (response: any) {
        console.error(response);
      }
  );
  rzp.open();
}

async function getRazorpayInstance(): Promise<any> {
  // @ts-ignore
  if (window && window.Razorpay) {
    return Razorpay;
  } else {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = false;
    s.src = "https://checkout.razorpay.com/v1/checkout.js";
    const x = document.getElementsByTagName("script")[0];
    x.parentNode?.insertBefore(s, x);

    return await new Promise((resolve, reject) => {
      s.onload = function () {
        // @ts-ignore
        resolve(window.Razorpay);
      };
    });
  }
}
