import { emptyStateDecoration } from "~/assets/assets";

export const EmptyState = ({
  primaryText,
  secondaryText,
  classList,
}: {
  primaryText: string;
  secondaryText: string;
  classList?: string;
}) => {
  return (
    <div
      class={`${classList} my-7 flex flex-col items-center justify-center gap-1 py-16`}
    >
      <img
        src={emptyStateDecoration}
        alt="No gift cards to show"
        class="h-[140px] w-[140px]"
      />
      <div class="text-bold">{primaryText}</div>
      <div class="text-medium text-baseSecondaryMedium">{secondaryText}</div>
    </div>
  );
};
