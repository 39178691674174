type ZIndexes = {
  modal: number;
  modalOff: number;
  header: number;
  default: number;
  orderSuccessHeaderImage: number;
  orderSuccessHeaderTick: number;
  infiniteScroll: number;
  categoryStrip: number;
};

export const zIndexes: ZIndexes = {
  modal: 20,
  modalOff: -10,
  header: 17,
  default: 0,
  orderSuccessHeaderImage: 15,
  orderSuccessHeaderTick: 16,
  infiniteScroll: -999,
  categoryStrip: 10,
};
